import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorService } from "./services/interceptor/http-interceptor.service";
import { AgmCoreModule } from "@agm/core";
import { apiList } from "./services/https/api-list";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { AgGridModule } from "ag-grid-angular";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { AllJSONFile } from "./core/all-json-file";
import { TranslateService } from "@ngx-translate/core";

import { TranslateModule, TranslatePipe } from "@ngx-translate/core";

// import ngx-translate and the http loader
import { TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ServiceWorkerModule, SwUpdate } from "@angular/service-worker";
import { environment } from "../environments/environment";
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
import { MessagingService } from "./services/messaging.service";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { MaterialModule } from "./material/material.module";
import { MaterialExtensionsExperimentalModule, MaterialExtensionsModule } from "@ng-matero/extensions";
import { RouteReuseStrategy } from "@angular/router";
import { MaltsevRouteReuseStrategy } from "./maltsev-reuse";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [AppComponent, NotfoundComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gApiKey,
      // apiKey: "AIzaSyA2uGW4WNagshJNWzFPzfnHZ0nFeGduCe8",
      libraries: ["drawing", "places", "geometry"],
    }),
    NgxUiLoaderModule,
    PdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MaterialExtensionsModule,
    MaterialExtensionsExperimentalModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production, registrationStrategy: "registerImmediately" }),
    // ServiceWorkerModule.register('ngsw-worker.js', {scope:"/"})
  ],
  providers: [
    SwUpdate,
    MessagingService,
    TranslateService,
    TranslatePipe,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    apiList,
    AllJSONFile,
    {
      provide: RouteReuseStrategy,
      useClass: MaltsevRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

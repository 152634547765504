import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  zoneData = [];
  geofenceData = [];
  selectedZoneID: string;
  selectedGeofenceId: string;
  backToListUrl = "/taxi/admin";
  accessDetails: any;

  constructor(private currencyPipe: CurrencyPipe) {}

  presentsToast(type: any, position: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
  }

  confirmToast() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  openDialog(type, message) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: type,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
    });
  }

  currencyFormat(number) {
    // if (number == 0 || number || !number) {
    //   return "$0.00";
    // }
    // return new Intl.NumberFormat().format(number);
    // if (number == 0) {
    //   return "0.00";
    // } else {
    return this.currencyPipe.transform(number, "USD");
    // return number.toLocaleString("en-US");
    // }
  }
}

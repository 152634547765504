import { Injectable } from "@angular/core";

@Injectable()
export class apiList {
  login: string = "/login";
  logout: string = "/logout";
  changePassword: string = "/changePassword";
  forgotPassword: string = "/forgotPassword";
  forgotChangePassword: string = "/forgotChangePassword";
  getModules: string = "/getModules";
  adminUseregister: string = "/adminUseregister";
  uploadFile: string = "/uploadFile";
  getDocument: string = "/getDocument";
  addDocument: string = "/addDocument";
  updateDocument: string = "/updateDocument";
  addUser: string = "/addUser";
  addVehicleCategory: string = "/addVehicleCategory";
  editGeofence: string = "/editGeofence";
  getGeofenceByZoneAndFenceId: string = "/getGeofences";
  getGeofence: string = "/getGeofences";
  getZonesByZoneId: string = "/getZones";
  getAllZone: string = "/getZones";
  editZone: string = "/editZone";
  getDefaultPaymentGateWay: string = "/getDefaultPaymentGateWay";
  getUserProfile: string = "/getUserProfile";
  addDriver: string = "/addDriver";
  getDriverProfile: string = "/getDriverProfile";
  updateUserProfile: string = "/updateUserProfile";
  updateDriverProfile: string = "/updateDriverProfile";
  addZone: string = "/addZone";
  addGeofence: string = "/addGeofence";
  getVehicleCatgory: string = "/getVehicleCategory";
  deleteGeofence: string = "/deleteGeofence";
  deleteZone: string = "/deleteZone";
  getAllUserProfile: string = "/getAllUserProfile";
  getAllDriverProfile: string = "/getAllDriverProfile";
  deleteBlockUnBlockDeactivateUserProfile: string = "/deleteBlockUnBlockDeactivateUserProfile";
  deleteBlockUnBlockDeactivateDriverProfile: string = "/deleteBlockUnBlockDeactivateDriverProfile";
  getDashboardCount: string = "/getDashboardCount";
  addPaymentGatewey: string = "/addPaymentGatewey";
  getPaymentGatewey: string = "/getPaymentGatewey";
  getAdminUserProfile: string = "/getAdminUserProfile";
  updateAdminUserProfile: string = "/updateAdminUserProfile";
  adminUserRegister: string = "/adminUserRegister";
  getDefaultLanguage: string = "/getDefaultLanguage";
  addLanguage: string = "/addLanguage";
  editLanguage: string = "/updateLanguage";
  deleteLanguage: string = "/deleteLanguage";
  getLanguage: string = "/getLanguage";
  deleteAdminUserProfile: string = "/deleteAdminUserProfile";
  updateVehicleCategory: string = "/updateVehicleCategory";
  deleteVehicleCategory: string = "/deleteVehicleCategory";
  uploadDriverDocs: string = "/uploadDriverDocs";
  // addDiscount: string = '/addDiscount';
  // getDiscount: string = '/getDiscount';
  // updateDiscount: string = '/updateDiscount';
  deleteDocument: string = "/deleteDocument";
  createRideBooking: string = "/createRideBooking";
  createDeliveryBooking: string = "/createDeliveryBooking";
  getPackageSize: string = "/getPackageSize";
  addCms: string = "/addCms";
  getCms: string = "/getCms";
  getProfile: string = "/getProfile";
  updateAdminProfile: string = "/updateAdminProfile";

  verifyDriverDocuments: string = "/verifyDriverDocuments";
  approveDriver: string = "/approveDriver";
  getDefaultDriverDocument: string = "/getDefaultDriverDocument";
  addDriverDocument: string = "/addDriverDocument";
  getDriverDocument: string = "/getDriverDocument";

  getBookingsHistoryOfDriver: string = "/getBookingsHistoryOfDriver";
  getAllLeadsData: string = "/getAllLeadsData";
  updateLead: string = "/updateLead";
  deleteLead: string = "/deleteLead";

  addLoyality: string = "/addLoyalityPoint";
  updateLoyality: string = "/editLoyalityPoint";
  getLoyality: string = "/getLoyalityPointDetails";
  getAllLoyality: string = "/getAllLoyalityPoint";
  deleteLoyalityPoint: string = "/deleteLoyalityPoint";
  approvedLoyalityPoint: string = "/approvedLoyalityPoint";
  activateDeActivateLoyalityPoint: string = "/activateDeActivateLoyalityPoint";
  getAllUser: string = "/getAllUser";
  getAllDriver: string = "/getAllDriver";
  getAllDeleviryPartner: string = "/getAllDeleviryPartner";
  applyedLoyalityPoint: string = "/applyedLoyalityPoint";
  getAllUserLoyalityPoint: string = "/getAllUserLoyalityPoint";
  getBookingsHistoryOfUser: string = "/getBookingsHistoryOfUser";
  addHelpCenter: string = "/addHelpCenter";
  getHelpCenter: string = "/getHelpCenter";
  updateHelpCenter: string = "/updateHelpCenter";
  updateCms: string = "/updateCms";

  getAllBusinessPartnerMapReduceExport: string = "/getAllBusinessPartnerMapReduceExport";
  getAllSalesExecutiveMapReduceExport: string = "/getAllSalesExecutiveMapReduceExport";
  getAllDeliveryPartnerMapReduceExport: string = "/getAllDeliveryPartnerMapReduceExport";
  getAdminUserProfileExport: string = "/getAdminUserProfileExport";
  getAllUserProfileExport: string = "/getAllUserProfileExport";
  getAllRiderProfileExport: string = "/getAllRiderProfileExport";
  getAllDriverProfileExport: string = "/getAllDriverProfileExport";

  getAllBusinessPartnerMapReduce: string = "/getAllBusinessPartnerMapReduce";
  getAllDeliveryPartnerMapReduce: string = "/getAllDeliveryPartnerMapReduce";
  getAllSalesExecutiveMapReduce: string = "/getAllSalesExecutiveMapReduce";
  getAllRidersProfileMapReduce: string = "/getAllRidersProfileMapReduce";
  getAllDriverProfileMapReduce: string = "/getAllDriverProfileMapReduce";

  driverMapReduceSync: string = "/driverMapReduceSync";
  userMapReduceSync: string = "/userMapReduceSync";
  adminMapReduceSync: string = "/adminMapReduceSync";
  businessPartnerMapReduceSync: string = "/businessPartnerMapReduceSync";
  saleExecutiveMapReduceSync: string = "/saleExecutiveMapReduceSync";
  deliveryPartnerMapReduceSync: string = "/deliveryPartnerMapReduceSync";

  addDiscount: string = "/addDiscount";
  editDiscount: string = "/editDiscount";
  getAllDiscount: string = "/getAllDiscount";
  getDiscountDetails: string = "/getDiscountDetails";
  deleteDiscount: string = "/deleteDiscount";
  approvedDiscount: string = "/approvedDiscount";
  activateDeActivateDiscount: string = "/activateDeActivateDiscount";
  applyedDiscount: string = "/applyedDiscount";
  getAllUserDiscount: string = "/getAllUserDiscount";
  deleteUserDiscount: string = "/deleteUserDiscount";

  addWebsiteAdvertisement: string = "/addWebsiteAdvertisement";
  editWebsiteAdvertisement: string = "/editWebsiteAdvertisement";
  getAllWebsiteAdvertisement: string = "/getAllWebsiteAdvertisement";
  getWebsiteAdvertisementDetails: string = "/getWebsiteAdvertisementDetails";
  deleteWebsiteAdvertisement: string = "/deleteWebsiteAdvertisement";
  blockUnBlockWebsiteAdvertisement: string = "/blockUnBlockWebsiteAdvertisement";

  addBanner: string = "/addBanner";
  editBanner: string = "/editBanner";
  getAllBanner: string = "/getAllBanner";
  getBannerDetails: string = "/getBannerDetails";
  deleteBanner: string = "/deleteBanner";
  blockUnBlockBanner: string = "/blockUnBlockBanner";
  assignBanner: string = "/assignBanner";
  deleteuserFromBanner: string = "/deleteuserFromBanner";
  blockUnBlockDeactivateAdminUserProfile: string = "/blockUnBlockDeactivateAdminUserProfile";
  getAllUserBanner: string = "/getAllUserBanner";

  adminUser: string = "/adminUser";
  updateAdminUser: string = "/updateAdminUser";
  addAccessModules: string = "/addAccessModules";
  editAccessModules: string = "/editAccessModules";
  blockUnBlockDeactivateAccessModules: string = "/blockUnBlockDeactivateAccessModules";
  deleteAccessModules: string = "/deleteAccessModules";
  getAllAccessModules: string = "/getAllAccessModules";
  getAllAdminProfileMapReduce: string = "/getAllAdminProfileMapReduce";
  getAdminUserProfileDetails: string = "/getAdminUserProfileDetails";

  getAuditDetail: string = "/getAuditDetail";
  getAuditDetailExport: string = "/getAuditDetailExport";

  deleteUserLoyalityPoint: string = "/deleteUserLoyalityPoint";

  bankNames: string = "/bankNames";
  payout: string = "/payout";
  payoutExport: string = "/payoutExport";
  cpa: string = "/cpa";
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotfoundComponent } from "./notfound/notfound.component";
import { AuthGuard } from "./guards/auth/auth.guard";

const routes: Routes = [
  // {
  //   path: '*',
  //   component: NotfoundComponent
  // },
  // {
  //   path: '',
  //   component: NotfoundComponent
  // },
  {
    path: "taxi",
    loadChildren: () => import("./Taxi/taxi.module").then((m) => m.TaxiModule),
  },

  {
    path: "restaurant",
    loadChildren: () => import("./Restaurant/pages/restaurant.module").then((m) => m.RestaurantModule),
  },
  {
    path: "**",
    redirectTo: "taxi/dashboard",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })], //{ scrollPositionRestoration: "enabled" }
  exports: [RouterModule],
})
export class AppRoutingModule {}

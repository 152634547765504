import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from "firebase/app";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  token: any;
  currentMessage = new BehaviorSubject(null);
  notificationArr: any = [];
  notificationCount: any;

  constructor(
    // private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient) { }

  permitToNotify() {
    const messaging = firebase.messaging();
    messaging.requestPermission()
      .then(() => messaging.getToken().then((token: any) => {
        console.log(this.token);
        this.token = token
      }))
      .catch((err: any) => {
        console.log('Unable to get permission to notify.', err);
      });
  }
  
  receiveMessage() {
    return new Promise((resolve, reject) => {
      // this.angularFireMessaging.messages.subscribe(
      //   (payload: any) => {
      //     console.log("new message received. ", payload);
      //     this.getNotiFication(payload)
      //     this.notificationArr.push({ notification: payload.notification.body, isSeen: false });
      //     this.currentMessage.next(payload);
      //     return resolve(payload);
      //   })
    })
  }

  getNotiFication(payload) {
    var param = {
      "notification": payload.notification,
      "to": this.token
    }
    this.http.post('https://fcm.googleapis.com/fcm/send', param, this.header()).subscribe((res: any) => {
      console.log(res);
    })
  }

  header() {
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: 'key=AAAAefSnscw:APA91bEbZ0Masq0sFy_P4n6tix92ba6HADq_XWRHdVjvEEVKcCG9ZxYKOKvOJxSEiPaM6ocfaGzDSCMmQ7fcbi7_AYjfttTEWgJNEXp-DZTh9am38eGE_6oWkgXQBT31DicflLez_ay4'
      });
      const option = {
        headers
      };
      return option;
    }
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { apiList } from "./api-list";
import { BehaviorSubject, Observable } from "rxjs";
import * as io from "socket.io-client";
import { CommonService } from "../common/common.service";

@Injectable({
  providedIn: "root",
})
export class HttpsService {
  baseUrl: string = environment.baseUrl;
  socket: any;

  constructor(private http: HttpClient, private apiList: apiList, private common: CommonService) {}

  addAuditDetail(content) {
    this.socket = io.connect("https://appgrowthcompany.com:4015", {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 3000,
      reconnectionAttempts: Infinity,
    });
    this.socket.emit("addAuditDetail", content);
    this.catchError();
  }
  catchError() {
    this.socket.on("error", (data) => {
      console.log(data);
      this.common.presentsToast("error", "top-end", "Error");
    });
  }

  httpPost(url: string, params): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }

  httpPutWithHeader(url: string, params: any): Observable<Object> {
    return this.http.put(this.baseUrl + this.apiList[url], params, this.header());
  }

  httpPostWithHeader(url: string, params: any): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params, this.header());
  }

  httpGetWithHeader(url: string, params): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url] + "?" + params, this.header());
  }
  httpGetWithHeaderId(url: string, params): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url] + "/" + params, this.header());
  }

  httpGet(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }

  httpDeleteWithHeaderAndId(url: string, id, isFormData): Observable<Object> {
    return this.http.delete(this.baseUrl + this.apiList[url] + "/" + id, this.header());
  }

  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append("image", fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: "multipart/form-data",
      authorization: localStorage.accessToken,
    });
    const headersObj = { headers };
    return this.http.post(this.baseUrl + this.apiList["uploadFile"], formData, headersObj);
  }

  header() {
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      const headers = new HttpHeaders({
        "cache-control": "no-cache",
        "content-type": "application/json",
        authorization: localStorage.accessToken,
      });
      const option = {
        headers,
      };
      return option;
    }
  }
}
